define("discourse/plugins/docker_manager/discourse/components/docker-manager/upgrade-notice", ["exports", "@glimmer/component", "@ember/routing", "@ember/service", "discourse-common/helpers/i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _routing, _service, _i18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class UpgradeNotice extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        {{#if this.currentUser.admin}}
          {{#unless @versionCheck.upToDate}}
            <div class="upgrades-banner">
              {{i18n "admin.docker.outdated_notice"}}
    
              <LinkTo @route="update">
                {{i18n "admin.docker.perform_update"}}
              </LinkTo>
            </div>
          {{/unless}}
        {{/if}}
      
    */
    {
      "id": "AKBA03cq",
      "block": "[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"admin\"]],[[[41,[51,[30,1,[\"upToDate\"]]],[[[1,\"        \"],[10,0],[14,0,\"upgrades-banner\"],[12],[1,\"\\n          \"],[1,[28,[32,0],[\"admin.docker.outdated_notice\"],null]],[1,\"\\n\\n          \"],[8,[32,1],null,[[\"@route\"],[\"update\"]],[[\"default\"],[[[[1,\"\\n            \"],[1,[28,[32,0],[\"admin.docker.perform_update\"],null]],[1,\"\\n          \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null]],[]],null],[1,\"  \"]],[\"@versionCheck\"],false,[\"if\",\"unless\"]]",
      "moduleName": "/srv/discourse/app/assets/javascripts/discourse/discourse/plugins/docker_manager/discourse/components/docker-manager/upgrade-notice.js",
      "scope": () => [_i18n.default, _routing.LinkTo],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = UpgradeNotice;
});